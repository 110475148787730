import React from 'react'
import { urlWithSearchParamsHandler, prepareParagraph, srcSetProps, sanityImageUrl } from '../../../utils/format'
import { Link } from 'gatsby'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ table }) => {
  return (
    <div className={styles.table}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{table.title}</h2>

        <div className={cx(styles.item, styles.itemDesktop)}>
          <div className={styles.row}>
            <div className={styles.cell}>
              <p className={styles.cellHeader}>{table.title_column_1}</p>
            </div>
            <div className={styles.cell}>
              <p className={styles.cellTitle}>{table.title_column_2}</p>
              <p className={styles.cellInfo}>{prepareParagraph(table.title_desc_2)}</p>
            </div>
            <div className={styles.cell}>
              <p className={styles.cellSmall}>{table.title_sm_column_3}</p>
              <p className={styles.cellTitle}>{table.title_column_3}</p>
              <p className={styles.cellInfo}>{prepareParagraph(table.title_desc_3)}</p>
            </div>
            <div className={styles.cell}>
              <p className={styles.cellTitle}>{table.title_column_4}</p>
              <p className={styles.cellInfo}>{prepareParagraph(table.title_desc_4)}</p>
            </div>
            <div className={styles.cell}>
              <p className={styles.cellTitle}>{table.title_column_5}</p>
              <p className={styles.cellInfo}>{prepareParagraph(table.title_desc_5)}</p>
            </div>
          </div>
          {table.tableRows &&
            table.tableRows.map((row, index) => (
              <div className={styles.row}>
                <div className={styles.cell}>
                  <p className={styles.cellHeader}>{row.column_1}</p>
                </div>
                <div className={styles.cell}>
                  <p className={styles.cellTitle}>{row.column_2}</p>
                </div>
                <div className={styles.cell}>
                  <p className={styles.cellTitle}>{row.column_3}</p>
                </div>
                <div className={styles.cell}>
                  <p className={styles.cellTitle}>{row.column_4}</p>
                </div>
                <div className={styles.cell}>
                  <p className={styles.cellTitle}>{row.column_5}</p>
                </div>
              </div>
            ))}
        </div>

        <div className={cx(styles.item, styles.itemMobile)}>
          <div className={styles.line}>
            <p className={styles.lineSmall}>{table.title_sm_column_3}</p>
            <p className={styles.lineTitle}>{table.title_column_3}</p>
            <p className={styles.lineInfo}>{prepareParagraph(table.title_desc_3)}</p>
            {table.tableRows &&
              table.tableRows.map((row, index) => (
                <div className={styles.info}>
                  <div className={styles.infoLeft}>{row.column_1}</div>
                  <div className={styles.infoRight}>{row.column_3}</div>
                </div>
              ))}
          </div>
          <div className={styles.line}>
            <p className={styles.lineTitle}>{table.title_column_4}</p>
            <p className={styles.lineInfo}>{prepareParagraph(table.title_desc_4)}</p>
            {table.tableRows &&
              table.tableRows.map((row, index) => (
                <div className={styles.info}>
                  <div className={styles.infoLeft}>{row.column_1}</div>
                  <div className={styles.infoRight}>{row.column_4}</div>
                </div>
              ))}
          </div>
          <div className={styles.line}>
            <p className={styles.lineTitle}>{table.title_column_2}</p>
            <p className={styles.lineInfo}>{prepareParagraph(table.title_desc_2)}</p>
            {table.tableRows &&
              table.tableRows.map((row, index) => (
                <div className={styles.info}>
                  <div className={styles.infoLeft}>{row.column_1}</div>
                  <div className={styles.infoRight}>{row.column_2}</div>
                </div>
              ))}
          </div>
          <div className={styles.line}>
            <p className={styles.lineTitle}>{table.title_column_5}</p>
            <p className={styles.lineInfo}>{prepareParagraph(table.title_desc_5)}</p>
            {table.tableRows &&
              table.tableRows.map((row, index) => (
                <div className={styles.info}>
                  <div className={styles.infoLeft}>{row.column_1}</div>
                  <div className={styles.infoRight}>{row.column_5}</div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}
