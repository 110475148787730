import React from 'react'
import { urlWithSearchParamsHandler, srcSetProps, sanityImageUrl } from '../../../utils/format'
import { Link } from 'gatsby'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ info }) => {
  return (
    <div
      className={cx(styles.info, {
        [styles.infoWhite]: info.isWhite,
      })}
    >
      <ul className={styles.breadcrumbs}>
        {info.breadcrumbs &&
          info.breadcrumbs.map((item, index) => (
            <li key={item._key}>
              <Link className={styles.breadcrumbsLink} to={item.url}>
                {item.text}
              </Link>
            </li>
          ))}
      </ul>

      <div className={styles.wrap}>
        <div className={styles.image}>
          <img className={styles.imageSrc} {...srcSetProps(sanityImageUrl(info?.image))} alt={info.image?.caption} />
        </div>

        <div className={styles.content}>
          <div className={styles.text}>
            <a href={info.rating?.url} target="_blank" className={styles.rating}>
              <img
                src={sanityImageUrl(info.rating?.image)}
                className={styles.ratingImage}
                alt={info.rating?.image.caption}
              />
              <span className={styles.ratingText}>{info.rating?.linkText}</span>
            </a>
          </div>

          <h1 className={styles.title}>{info.title}</h1>

          <div className={styles.details}>
            <p className={styles.description}>{info.description}</p>

            <ul className={styles.prices}>
              {info.prices &&
                info.prices.map((price, index) => (
                  <li className={styles.pricesItem} key={price._key}>
                    <div className={styles.pricesLeft}>{price.price}</div>
                    <div className={styles.pricesRight}>{price.information}</div>
                  </li>
                ))}
            </ul>

            <ul className={styles.benefits}>
              {info.benefits &&
                info.benefits.map((benefit, index) => (
                  <li key={benefit._key}>
                    <img src={sanityImageUrl(benefit.image)} alt={benefit.image?.caption} />
                    <span>{benefit.text}</span>
                  </li>
                ))}
            </ul>

            <a href={info.buttonUrl} onClick={urlWithSearchParamsHandler} className={cx(styles.link, styles.linkSm)}>
              {info.buttonText}
            </a>

            <ul className={styles.extra}>
              {info.benefitsText &&
                info.benefitsText.map((benefit, index) => (
                  <li key={benefit._key}>
                    <span>{benefit.text}</span>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>

      <a href={info.buttonUrl} onClick={urlWithSearchParamsHandler} className={cx(styles.link, styles.linkWide)}>
        {info.buttonText}
      </a>
    </div>
  )
}
