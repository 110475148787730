import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import ProductSchema from '../components/Shared/Schema/Product'
import FaqSchema from '../components/Shared/Schema/Faq'
import Info from '../components/WeMeasurePage/Info'
import Process from '../components/WeMeasurePage/Process'
import Measure from '../components/WeMeasurePage/Measure'
import Table from '../components/WeMeasurePage/Table'
import Faq from '../components/WeMeasurePage/Faq'

export default ({ pageContext: { data } }) => {
  return (
    <Layout data={data} signUpUrl={data.info?.buttonUrl} signUpText={data.info?.buttonText}>
      <SEO {...data.seo} />
      <ProductSchema {...data.productSchema} />
      {data?.faq?.items && <FaqSchema questions={data.faq.items} />}
      <Info info={data.info} />
      <Process process={data.measuringProcess} />
      <Measure measure={data.weMeasure} />
      <Table table={data.table} />
      <Faq faq={data.faq} />
    </Layout>
  )
}
